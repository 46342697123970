<template>
  <div>
    <div class="verification section-padding">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-7">
            <div class="auth-form card">
              <div class="card-header">
                <h4 class="card-title">Add New Account</h4>
              </div>
              <div class="card-body py-0">
                <div class="add-bank-card">
                  <div class="row my-4">
                    <div class="col-md-12">
                      <router-link to="add-bank-account" class="d-block">
                        <div class="media">
                          <span class="mr-3"><i class="fa fa-bank"></i></span>
                          <div class="media-body">
                            <h4 class="mt-0 mb-3">Bank account</h4>
                            <p>
                              I authorize Getcoins to verify this bank
                              account for deposits.
                            </p>
                            <p class="text-muted">
                              Prices are locked on the day of the transaction.
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.verification {
  height: calc(100vh - 115px);
}
</style>
